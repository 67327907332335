import { useBaseApi } from '~/composables/api/BaseApi';

export const serverAssetsApi = () => {
    const { apiAsset } = useBaseApi();
    const asset = path => apiAsset(path);

    return {
        asset,
    };
};
